/* OrderList.css */

.table-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 24px;
    animation: slide-up 0.3s ease-in-out;
  }
  
  .table-container h2 {
    font-size: 1.25rem;
    color: #4a5568;
    font-weight: 500;
  }
  
  .table-container .table-header {
    background-color: #f7fafc;
    font-weight: 600;
    color: #4a5568;
  }
  
  .table-container th,
  .table-container td {
    padding: 12px 16px;
    font-size: 0.875rem;
    color: #2d3748;
  }
  
  .table-container tbody tr {
    transition: background-color 0.2s;
  }
  
  .table-container tbody tr:hover {
    background-color: #edf2f7;
  }
  
  .order-status {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
    color: white;
  }
  
  .order-actions button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    font-size: 1rem;
    color: #4a5568;
    transition: color 0.2s;
  }
  
  .order-actions button:hover {
    color: #3182ce;
  }
  
  .order-actions button:last-child:hover {
    color: #e53e3e;
  }
  
  @media (max-width: 768px) {
    .mobile-card {
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      padding: 16px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      transition: box-shadow 0.2s;
    }
  
    .mobile-card:hover {
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    }
  
    .mobile-card .order-status {
      padding: 4px 8px;
      border-radius: 9999px;
      font-size: 0.75rem;
      color: white;
    }
  
    .mobile-card .order-actions {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
    }
  
    .mobile-card .order-actions button {
      color: #4a5568;
      transition: color 0.2s;
    }
  
    .mobile-card .order-actions button:hover {
      color: #3182ce;
    }
  }
  
  /* Animations */
  @keyframes slide-up {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  