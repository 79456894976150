/* Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Importing Fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  -webkit-user-drag: none;
}

.ql-container {
  min-height: 200px;
  border-radius: 0 0 0.375rem 0.375rem;
}

.ql-toolbar {
  border-radius: 0.375rem 0.375rem 0 0;
}

.ql-editor {
  min-height: 200px;
}

#sidebar-nav::-webkit-scrollbar {
  width: 0.25rem;
}

#sidebar-nav::-webkit-scrollbar-button {
  background-color: transparent;
  height: 0;
}

#sidebar-nav::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 99rem;
}

#sidebar-nav::-webkit-scrollbar-thumb:hover {
  background-color: #969696;
}

#main-container::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

#main-container::-webkit-scrollbar-button {
  background-color: transparent;
  width: 0;
  height: 0;
}

#main-container::-webkit-scrollbar-thumb {
  background-color: #969696;
  border-radius: 99rem;
}

#main-container::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

#GIFT-CARDS-CONTAINER::-webkit-scrollbar {
  width: 0.35rem;
  height: 0.35rem;
}

#GIFT-CARDS-CONTAINER::-webkit-scrollbar-track {
  background-color: transparent;
}

#GIFT-CARDS-CONTAINER::-webkit-scrollbar-button {
  background-color: transparent;
  width: 0;
  height: 0;
}

#GIFT-CARDS-CONTAINER::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.492);
  border-radius: 1rem;
}

#GIFT-CARDS-CONTAINER::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

#BUSINESS-SETUP-NAV::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

#BUSINESS-SETUP-NAV::-webkit-scrollbar-track {
  background-color: rgba(128, 128, 128, 0.163);
  border-radius: 1rem;
}

#BUSINESS-SETUP-NAV::-webkit-scrollbar-button {
  background-color: transparent;
  width: 0;
  height: 0;
}

#BUSINESS-SETUP-NAV::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.492);
  border-radius: 1rem;
}

#BUSINESS-SETUP-NAV::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

.custom-scroll::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: whitesmoke;
}

.custom-scroll::-webkit-scrollbar-button {
  background-color: transparent;
  width: 0;
  height: 0;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: lightgray;
}

.custom-scroll::-webkit-scrollbar-thumb:active {
  background-color: #c4c4c4;
}
